<template>
    <el-dialog title="编辑" :visible.sync="editVisible" width="600px" :before-close="closeEvent">
        <el-form ref="form" label-width="130px">
            <el-form-item size="small" label="上级名称:" required>
                <p v-text="editData.parentName"></p>
            </el-form-item>
            <el-form-item size="small" label="区域中文名称:" required>
                <el-input v-model="editData.foreignDivisionName" placeholder="请输入区域中文名称" style="width: 400px;" maxlength="9" show-word-limit></el-input>
            </el-form-item>
            <el-form-item size="small" label="区域英文名称:" required>
                <el-input v-model="editData.englishName" placeholder="请输入区域英文名称" maxlength="20" style="width: 400px;" show-word-limit></el-input>
            </el-form-item>

            <el-form-item size="small" label="所属大洲:" v-if="editData.parentId === '0'" required>
                <el-select style="width: 400px;" v-model="editData.island" placeholder="请选择大洲">
                    <el-option
                            v-for="item in isLandTypeList"
                            :key="item.dictValue"
                            :label="item.name"
                            :value="item.dictValue"
                    ></el-option>
                </el-select>
            </el-form-item>

            <el-form-item size="small" label="图标:" v-if="editData.parentId === '0'">
                <span style="margin-top: 20px">支持格式:jpg/png 240*240 3M</span>
                <el-upload
                        class="avatar-uploader"
                        :action="Base_url+'/admin/uploadCos'"
                        :show-file-list="false"
                        :on-success="imageSuccess"
                        :before-upload="imageUpload">
                    <img v-if="editData.fileUrl" :src="editData.fileUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="updateOverseasArea">确 定</el-button>
        <el-button size="small" @click="closeEvent">取 消</el-button>
      </span>
    </el-dialog>
</template>
<script>
    import {updateOverseasArea} from '../../api/bbi/foreign_division'
    import {Base_url} from '../../config/index'

    export default {
        data() {
            return {
                show: false,
                isLandTypeList: [],
                icon: '',
                key: '',
            }
        },

        props: {
            editData: Object,
            editVisible: Boolean
        },
        async created() {
            // console.log(this.editData)
            this.Base_url = Base_url
            this.key = this.editData.icon
            await this.getIslandType();
            // await this.getUrl();
        },
        methods: {
            // async getUrl() {
            //   let data  = {
            //     key:this.key
            //   }
            //   let res = await this.$get('/admin/getUrl',data);
            //   this.icon = res
            // },
            imageSuccess(res) {
                this.editData.fileUrl = res.file
                this.key = res.key
            },
            imageUpload(file) {
                const isJPG = file.type === 'image/jpeg';
                const isPNG = file.type === 'image/png';
                const isLt2M = file.size / 1024 / 1024 < 3;

                if (!isJPG && !isPNG) {
                    this.$message.error('请上传JPG或PNG格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传大小不能超过 3MB!');
                }
                return (isJPG || isPNG) && isLt2M;
            },
            async getIslandType() {
                let dataSet = await this.$get('/admin/getDictDetailList/ISLAND_TYPE/' + Math.random());
                this.isLandTypeList = dataSet;
            },
            closeEvent() {
                this.$emit('closeEdit')
            },
            async updateOverseasArea() {
                if (!this.editData.foreignDivisionName) {
                    this.$message({
                        showClose: true,
                        message: '请输入区域中文名称',
                        type: 'error'
                    });
                    return
                }
                if (!this.editData.englishName) {
                    this.$message({
                        showClose: true,
                        message: '请输入区域英文名称',
                        type: 'error'
                    });
                    return
                }
                if (!this.editData.island) {
                    this.$message({
                        showClose: true,
                        message: '请选择所属大洲',
                        type: 'error'
                    });
                    return
                }
                let data = {
                    id: this.editData.id,
                    parentId: this.editData.parentId,
                    foreignDivisionName: this.editData.foreignDivisionName,
                    englishName: this.editData.englishName,
                    island: this.editData.island == 'null' ? '' : this.editData.island,
                    icon: this.key == 'null' ? '' : this.key,
                };

                let res = await updateOverseasArea(data);
                if (res.code == 200) {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'success'
                    });
                    this.$emit('editSuccess')
                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }
            }
        }
    }
</script>

<style scoped>

</style>
