import axios from 'axios'
import {createSearchParams} from '../../utils/main'
import { Base_url} from '../../config'


//获取海外国家区域列表
function getOverseasAreaList(){
    return new Promise(resolve => {
        axios({
            method: 'get',
            url: Base_url+'/admin/getOverseasAreaList',
        }).then((res)=>{
            resolve(res.data)
        })
    })
}

//获取全部大区列表
/*function getChinaRegionList(){
    return new Promise(resolve => {
        axios({
            method: 'get',
            url: Base_url+'/admin/selectChinaRegionIdList',
        }).then((res)=>{
            resolve(res.data)
        })
    })
}*/

//添加海外区域
function addRoot(foreignDivisionName,divisionCode){
    return new Promise(resolve => {
        axios({
            method: 'post',
            url: Base_url+'/admin/saveOverseasArea',
            params:{
                parentId:"0",
                foreignDivisionName:foreignDivisionName,
                divisionCode:divisionCode
            }
        }).then((res)=>{
            resolve(res.data)
        })
    })
}

//添加海外地区
function saveOverseasArea(data){
    return new Promise(resolve => {
        axios({
            method: 'post',
            url: Base_url+'/admin/saveOverseasArea',
            data: createSearchParams(data)
        }).then((res)=>{
            resolve(res.data)
        })
    })
}

//编辑海外地区
function updateOverseasArea(data){
    return new Promise(resolve => {
        axios({
            method: 'post',
            url: Base_url+'/admin/saveOverseasArea',
            data: createSearchParams(data)
        }).then((res)=>{
            resolve(res.data)
        })
    })
}

//删除海外地区
function deleteOverseasArea(pId){
    return new Promise(resolve => {
        axios({
            method: 'delete',
            url: Base_url+'/admin/deleteOverseasArea',
            params:{
                id:pId
            }
        }).then((res)=>{
            resolve(res.data)
        })
    })
}


export {
    getOverseasAreaList,
    addRoot,
    updateOverseasArea,
    deleteOverseasArea,
    saveOverseasArea
}
