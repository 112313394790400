<template>
  <div>
    <div class="e-breadcrumb">海外国家地区</div>
    <div class="order-table-wrapper">
      <div class="e-card">
        <div class="e-card-body" style="padding-top:35px">
          <el-button style="margin-left: 0px" type="primary" size="small" @click="addRootEvent">添加地区</el-button>
          <el-row>
            <el-card style="margin-left: 0px;margin-top: 10px;margin-right:10px;width:300px;display: inline-block"
                     v-for="(item,key) in islandMap" :key="key"
                     v-if="item.children.length">
              <div slot="header" class="clearfix">
                <span>{{item.name}}</span>
              </div>
              <div style="height: 300px;overflow-y: auto">
                <v-tree v-loading="treeLoading" :nodes="item.children">
                  <template slot-scope="{node}">
                    <a href="javascript:;" v-if="node.parentId === '0'" @click.stop="addEvent(node)"
                       class="v-tree-plus"></a>
                    <a href="javascript:;" @click.stop="editEvent(node)" class="v-tree-edit"></a>
                    <a href="javascript:;" @click.stop="removeEvent(node)" class="v-tree-remove"></a>
                  </template>
                </v-tree>
              </div>
            </el-card>
          </el-row>
        </div>
      </div>
    </div>
    <el-dialog title="添加" width="600px" :visible.sync="addRootVisible">
      <el-form ref="form" label-width="130px">
        <el-form-item size="small" label="国家中文名称:" required>
          <el-input v-model="foreignDivisionName" placeholder="请输入国家中文名称" style="width: 400px;" maxlength="9" show-word-limit></el-input>
        </el-form-item>
        <el-form-item size="small" label="国家英文名称:" required>
          <el-input v-model="englishName" placeholder="请输入国家英文名称" style="width: 400px;" maxlength="20" show-word-limit></el-input>
        </el-form-item>
        <el-form-item size="small" label="所属大洲:" required>
          <el-select style="width: 400px;" v-model="isLand" placeholder="请选择大洲">
            <el-option
                v-for="item in isLandTypeList"
                :key="item.id"
                :label="item.name"
                :value="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item size="small" label="图标:">
          <span style="margin-top: 20px">支持格式:jpg/png 240*240 3M</span>
          <el-upload
              class="avatar-uploader"
              :action="Base_url+'/admin/uploadCos'"
              :show-file-list="false"
              :on-success="imageSuccess"
              :before-upload="imageUpload">
            <img v-if="icon" :src="icon" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
              <el-button size="small" type="primary" @click="addRoot">确 定</el-button>
              <el-button size="small" @click="addRootVisible = false">取 消</el-button>
            </span>
    </el-dialog>
    <!-- 删除提示框 -->
    <el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
      <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="delOverseasArea">确 定</el-button>
        <el-button size="small" @click="delVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <Insert v-if="addVisible" :addVisible="addVisible" @closeInsert="closeInsert" @addSuccess="addSuccess" :id="id"
            :parentName="parentName"/>
    <Edit
        v-if="editVisible"
        :editVisible="editVisible"
        @closeEdit="closeEdit"
        @editSuccess="editSuccess"
        :editData="editData"
    />
  </div>
</template>

<script>
import Insert from './insert'
import Edit from './edit'
import vTree from '@/components/common/v-tree/tree'
import {dataFormat} from '../../utils/main'
import {Base_url} from '../../config/index'
import {getOverseasAreaList, deleteOverseasArea} from '../../api/bbi/foreign_division'

export default {
    data() {
        return {
            treeLoading: false,
            foreignDivisionName: "",
            englishName: '',
            divisionCode: "",
            areaCode: "",
            chinaRegionItem: [],
            isLandTypeList: [],
            chinaRegionId: '',
            isLand: '',
            icon: '',
            pId: '',
            nodes: [],
            list: {},
            options: {
                data: {
                    simpleData: {
                        idKey: "id",
                        pIdKey: "parentId",
                    },
                    key: {
                        name: "foreignDivisionName",
                        children: 'children'
                    }
                }
            },
            id: '',
            key: '',
            parentName: '',
            addRootVisible: false,
            addVisible: false,
            editVisible: false,
            delVisible: false,
            editData: {},
            islandMap: {}
        }
    },
    async created() {
        this.Base_url = Base_url
        await this.getIslandType();
        this.searchEvent();
        /*this.getChinaRegionList();*/
    },
    methods: {
        imageSuccess(res) {
            // this.resourcesUrl = res.fileUrl
            this.icon = res.file
            this.key = res.key
        },
        imageUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isPNG = file.type === 'image/png';
            const isLt2M = file.size / 1024 / 1024 < 3;

            if (!isJPG && !isPNG) {
                this.$message.error('请上传JPG或PNG格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传大小不能超过 3MB!');
            }
            return (isJPG || isPNG) && isLt2M;
        },
        async getIslandType() {
            let dataSet = await this.$get('/admin/getDictDetailList/ISLAND_TYPE/' + Math.random());
            this.isLandTypeList = dataSet;
            const islandMap = {};
            dataSet.forEach(item => {
                islandMap[item.dictValue] = {
                    name: item.name,
                    children: []
                };
                this.islandMap = islandMap;
            })
        },
        async searchEvent() {
            this.treeLoading = true;

            for (let key in this.islandMap) {
                this.islandMap[key].children = [];
            }
            let res = await getOverseasAreaList();
            let data = dataFormat(this.options, res)
            // this.nodes = data.list
            data.list.forEach(item => {
                if (this.islandMap[item.island]) {
                    this.islandMap[item.island].children.push(item)
                }
            })
            this.list = data.map
            this.treeLoading = false;
        },
        //添加顶级权限
        async addRoot() {
            if (!this.foreignDivisionName) {
                this.$message({
                    showClose: true,
                    message: '请输入区域中文名称',
                    type: 'error'
                });
                return
            }
            if (!this.englishName) {
                this.$message({
                    showClose: true,
                    message: '请输入区域英文名称',
                    type: 'error'
                });
                return
            }
            if (this.isLand === '' || this.isLand < 0) {
                this.$message({
                    showClose: true,
                    message: '请选择所属大洲',
                    type: 'error'
                });
                return
            }
            let data = {
                foreignDivisionName: this.foreignDivisionName,
                englishName: this.englishName,
                island: this.isLand,
                icon: this.key,
                parentId: 0
            }
            let res = await this.$post('/admin/saveForeignDivision', data);
            if (res.code == 200) {
                this.searchEvent();
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: 'success'
                });
              this.foreignDivisionName = '',
              this.englishName = ''
              this.isLand = '',
              this.icon = '',
              this.addRootVisible = false
            } else {
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: 'error'
                });
            }
        },
        addRootEvent() {
            this.addRootVisible = true
        },

        addEvent(node) {
            console.log(node)
            this.addVisible = true;
            this.id = node.id
            this.parentName = node.foreignDivisionName + '(' + node.englishName + ')'
        },
        editEvent(node) {
            console.log(node)
            this.editData = {
                id: node.id,
                parentId: node.parentId,
                foreignDivisionName: node.foreignDivisionName,
                englishName: node.englishName,
                fileUrl: node.fileUrl,
                divisionCode: node.divisionCode,
                areaCode: node.areaCode,
                longitude: node.longitude,
                island: node.island + '',
                icon: node.icon,
                latitude: node.latitude,
                parentName: (this.list[node.parentId] ? this.list[node.parentId].foreignDivisionName + '(' + this.list[node.parentId].englishName + ')' : '无')
            };
            this.editVisible = true;
        },
        removeEvent(node) {
            this.pId = node.id
            this.delVisible = true
        },
        async delOverseasArea() {
            let res = await deleteOverseasArea(this.pId)
            if (res.code == 200) {
                this.searchEvent();
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: 'success'
                });
                this.delVisible = false
                // this.searchEvent();
            } else {
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: 'error'
                });
            }
        },
        addSuccess() {
            this.closeInsert();
            this.searchEvent();
        },

        closeInsert() {
            this.addVisible = false;
        },
        editSuccess() {
            this.closeEdit();
            this.searchEvent();
        },
        closeEdit() {
            this.editVisible = false;
        }
    },
    components: {
        vTree,
        Insert,
        Edit
    }
}
</script>

<style>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
