<template>
    <el-dialog title="新增" :visible.sync="addVisible" :before-close="closeEvent" width="600px">
        <el-form ref="form" label-width="130px">
            <el-form-item size="small" label="上级名称:" required>
                <p v-text="parentName"></p>
            </el-form-item>
            <el-form-item size="small" label="区域中文名称:" required>
                <el-input v-model="foreignDivisionName" placeholder="请输入区域中文名称" maxlength="9" style="width: 400px;" show-word-limit></el-input>
            </el-form-item>
            <el-form-item size="small" label="区域英文名称:" required>
                <el-input v-model="englishName" placeholder="请输入区域英文名称" maxlength="20" style="width: 400px;" show-word-limit></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="saveOverseasArea">确 定</el-button>
        <el-button size="small" @click="closeEvent">取 消</el-button>
      </span>
    </el-dialog>
</template>
<script>
    export default {
        data() {
            return {
                englishName:'',
                foreignDivisionName: '',
                divisionCode: ''
            }
        },
        props: {
            id: String,
            parentName: String,
            addVisible: Boolean
        },
        watch: {
            addVisible(val) {
                if (!val) {
                    this.foreignDivisionName = '',
                        this.divisionCode = ''
                }
            }
        },
        methods: {
            closeEvent() {
                this.$emit('closeInsert')
            },
            async saveOverseasArea() {
                if (!this.foreignDivisionName) {
                    this.$message({
                        showClose: true,
                        message: '请输入区域中文名称',
                        type: 'error'
                    });
                    return
                }
                if (!this.englishName) {
                    this.$message({
                        showClose: true,
                        message: '请输入区域英文名称',
                        type: 'error'
                    });
                    return
                }
                let data = {
                    parentId: this.id,
                    englishName:this.englishName,
                    foreignDivisionName: this.foreignDivisionName,
                };
                let res = await this.$post('/admin/saveForeignDivision', data);
                // let res = await saveOverseasArea(data);
                if (res.code == 200) {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'success'
                    });
                    this.$emit('addSuccess')
                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }
            }
        }
    }
</script>

<style scoped>

</style>
